import Vue from 'vue'
import _ from 'lodash';

function initialState() {
    return _.cloneDeep({
        publicPage: {},
        publicPages: []
    })
}


export default {
    namespaced: true,

    state: initialState(),

    getters: {
        getPublicPages(state) {
            return state.publicPages
        },

        getPublicPage(state) {
            return state.publicPage
        }
    },

    mutations: {
        reset(state) {
            Object.assign(state, initialState());
        },

        setPublicPages(state, publicPages) { 
            state.publicPages = publicPages
        },

        setPublicPage(state, publicPage) {
            state.publicPage = publicPage
        }
    },

    actions: {
        async loadPublicPages(ctx) {
            const headers = await ctx.dispatch("auth/getHeaders", null, { root: true })

            const url = '/api/bookmee/publicpage'
            return await Vue.prototype.axios({
                headers: headers,
                method: "get",
                url: url,
            }).then(resp => {
                const publicPages = resp.data['values']['publicPages']
                ctx.commit('setPublicPages', publicPages)
                return resp.status
            }).catch(error => {
                return error.response.status
            })
        },

        async loadPublicPage(ctx, {id, params={}}) {
            const headers = await ctx.dispatch("auth/getHeaders", null, { root: true })

            const url = `/api/bookmee/publicpage/${id}`
            return await Vue.prototype.axios({
                headers: headers,
                method: "get",
                url: url,
                params: params
            }).then(resp => {
                const publicpage = resp.data['values']['publicpage']
                ctx.commit('setPublicPage', publicpage)
                return resp.status
            }).catch(error => {
                return error.response.status
            })
        },

        async createPublicPage(ctx, publicPage) {
            const headers = await ctx.dispatch("auth/getHeaders", null, { root: true })

            const url = `/api/bookmee/publicpage`
            return await Vue.prototype.axios({
                headers: headers,
                method: "post",
                url: url,
                data: publicPage
            }).then(resp => {
                return resp.status
            }).catch(error => {
                return error.response.status
            })
        },

        async updatePublicPage(ctx, publicPage) {
            const headers = await ctx.dispatch("auth/getHeaders", null, { root: true })

            const url = `/api/bookmee/publicpage/${publicPage.id}`
            return await Vue.prototype.axios({
                headers: headers,
                method: "put",
                url: url,
                data: publicPage
            }).then(resp => {
                return resp.status
            }).catch(error => {
                return error.response.status
            })
        },

        async deletePublicPage(ctx, id) {
            const headers = await ctx.dispatch("auth/getHeaders", null, { root: true })

            const url = `/api/bookmee/publicpage/${id}`
            return await Vue.prototype.axios({
                headers: headers,
                method: "delete",
                url: url,
            }).then(resp => {
                return resp.status
            }).catch(error => {
                return error.response.status
            })
        },
    }
}
