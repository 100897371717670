import Vue from 'vue'



export default {
    namespaced: true,
    
    
    state: {
        timeSlots: []
    },
    
    
    getters: {
        getTimeSlots(state) {
            return state.timeSlots 
        }
    },
    
    
    mutations: {
        addTimeSlot(state, item) {
            state.timeSlots.push(item)
        },
        setTimeSlots(state, timeSlots) {
            if (timeSlots === null) {
                state.timeSlots = []
            } else {
                state.timeSlots = timeSlots
            }
        }

    },
    
    
    actions: {
        async loadTimeSlots(ctx) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })
            const selectedCalender = ctx.rootGetters['calender/getSelectedCalender']

            const url = '/api/bookmee/calender/' + selectedCalender.id + '/timeslot'
            return await Vue.prototype.axios({
                method: "get",
                url: url,
                headers: headers
            }).then(resp => {
                const timeslots = resp.data.values.timeslots
                ctx.commit('setTimeSlots', timeslots)
            }).catch(error => {
                console.log(error)
                ctx.commit('setTimeSlots', null)
            })
        },


        async createTimeSlot(ctx, timeSlot) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })
            const selectedCalender = ctx.rootGetters['calender/getSelectedCalender']

            const url = '/api/bookmee/calender/' + selectedCalender.id + '/timeslot'
            return await Vue.prototype.axios({
                method: "post",
                url: url,
                data: timeSlot,
                headers: headers
            }).then(resp => {
                console.log(resp.status)
                ctx.dispatch('loadTimeSlots')       
            }).catch(error => {
                console.log(error)
                Vue.prototype.$toast.error("TimeSlot konnte nicht hinzugefügt werden")
            })
        },

        async deleteTimeSlot(ctx, id) {
            const selectedCalender = ctx.rootGetters['calender/getSelectedCalender']
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })

            const url = '/api/bookmee/calender/' + selectedCalender.id + '/timeslot/' + id
            return await Vue.prototype.axios({
                method: "delete",
                url: url,
                headers: headers
            }).then(resp => {
                console.log(resp.status)
                ctx.dispatch('loadTimeSlots')
            }).catch(error => {
                console.log("error")
                console.log(error)
            })
        }

        


    },
}

