<template>
  <div class="dashboard">
    <NotificationBar v-if="false" />
    <Navbar></Navbar>
    <AppNavbar></AppNavbar>


    <div class="container mt-5">
      <transition name="slide">
        <router-view></router-view>
      </transition>
    </div>



  </div>
</template>

<script>
import Navbar from '@/components/elements/Navbar.vue'
import AppNavbar from '@/components/elements/AppNavbar.vue'
import NotificationBar from '@/components/elements/NotificationBar.vue'

export default {
  name: 'Dashboard',
  components: {
    Navbar, 
    NotificationBar, 
    AppNavbar,
  },
  async created() {
    await this.$store.dispatch('calender/loadDefaultCalender')
  }


}
</script>
