import auth from './auth'
import calender from './calender'
import eventTypes from './eventTypes'
import timeSlots from './timeSlots'
import bookings from './bookings'
import timeSchedule from './timeSchedule'
import publicPage from './publicPage'




export default {
    auth,
    calender,
    eventTypes,
    timeSlots,
    bookings,
    timeSchedule,
    publicPage,
}