import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)


// https://www.youtube.com/watch?v=GRhkhSzyApc&t=1160s
import modules from './modules'
export default new Vuex.Store({
  modules,
  // plugins: [createPersistedState()],
  strict: process.env.NODE_ENV === 'development'
})
