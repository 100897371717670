<template>
  <div id="login-page" class="login-container">

    <div class="container" style="max-width: 500px;">
      <div class="mt-5" style="margin-top: 5rem !important;">
        <div class="row">
          <div class="col-12 mr-3 ml-3">
            <img src="../../assets/images/bookmee_de_logo.png" class="w-100" alt="">
          </div>
        </div>

        <vs-card>
          <div slot="header">
            <h3>Login</h3>
          </div>


          <div class="row">
            <div class="col-12 mr-3 ml-3 mt-3">
              <vs-input v-on:keyup.enter="login()" data-vv-validate-on="blur" name="email" icon="account_circle"
                icon-after="true" label-placeholder="Email" v-model="user.identifier" class="w-100 no-icon-border" />
            </div>

            <div class="col-12 mr-3 ml-3 mt-2 mb-4">
              <vs-input v-on:keyup.enter="login()" name="password" type="password" icon="key" icon-after="true"
                label-placeholder="Passwort" v-model="user.password" class="w-100 no-icon-border" />
            </div>
          </div>

          <!-- <div class="row">
            <div class="col-12 mr-3 ml-3 mt-2 mb-4">
              <router-link :to="{ name: 'signup'}">Noch kein Konto?</router-link>
            </div>
          </div> -->


          <div slot="footer">
            <vs-row vs-justify="space-between">
              <small class="mt-1">
                <router-link :to="{ name: 'signup'}">Noch kein Konto?</router-link>
              </small>
              <vs-button color="primary" icon="login" @click="login()" :disabled="isDisabled">Anmelden</vs-button>
            </vs-row>
          </div>


        </vs-card>

      </div>
    </div>
  </div>
</template>




<script>


export default {
  name: "Login",
  data() {
    return {
      isDisabled: false,
      user: {
        identifier: '',
        password: '',
      }
    }
  },

  async mounted() {
    console.log(await this.$store.dispatch('auth/isAuthenticated'))
    if (await this.$store.dispatch('auth/isAuthenticated')) {
      this.$router.push({ name: "dashboard.calender" })
    }
  },


  computed: {
    validateForm() {
        return this.email != '' && this.password != '';
    },
    loggedInUser() {
      return this.$store.getters['auth/getUser']
    }
  },
  methods: {
    async login() {
      this.isDisabled = true
      await this.$store.dispatch('auth/createLogin', this.user)

      if (await this.$store.dispatch('auth/isAuthenticated')) {
        await this.$store.dispatch('auth/loadUser')
        this.$vs.notify({
          time: 5000,
          title:'Angemeldet',
          text:'Willkommen bei Bookmee',
          color: 'dark'
        })
        this.$router.push({ name: 'dashboard.calender'})
        return
      }

      this.$vs.notify({
        time: 5000,
        title:'Eingeben Prüfen',
        text:'Bitte überprüfen Sie ihre Eingaben',
        color: 'danger'
      })
      this.isDisabled = false
    }
  }
      
}
</script>


<style scoped>
/* rgba(28, 27, 28, 1) */

  .login-container {
    background-size: cover; 
    width: 100vw;
    height: 100vh;
    display: flex; 
    /* background-color: rgba(28, 27, 28, 0.95); */
  }

</style>