<template>
    <div id="notificationBar" class="bg-warning text-center">
        Ihr Abbonemnet wird in kürze enden. Erhalten Sie jetzt 20% auf ihr nächstes.
    </div>
</template>
<script>
// import PasswordChangePopup from '@/components/PasswordChangePopup.vue'


export default {
    name: "NotificationBar",
}
</script>


<style scoped>
#notificationBar {
    font-size: 0.75rem;
    padding: 0.25rem;
}

</style>