import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// Bootstrap
import 'bootstrap/dist/css/bootstrap.css'

// VUESAX
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import 'material-icons/iconfont/material-icons.css';
Vue.use(Vuesax, {
  theme: {
    colors: {
      default: '#005B9B',
      // primary: 'rgb(246, 171, 54)',
      primary: '#0b1668',

      // success: 'rgb(23, 201, 100)',
      danger: 'rgb(206, 38, 38)',
      warning: 'rgb(255, 130, 0)',
      dark: 'rgb(28, 27, 28)'
    }
  }
})



// TIMEPICKER
import 'vue2-timepicker/dist/VueTimepicker.css'


// V-CALENDER
import VCalendar from 'v-calendar';

Vue.use(VCalendar, {
  componentPrefix: 'vc',
});



// BOOKMEE
import '@/assets/css/BOOKMEE.css'


// AXIOS
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
Vue.axios.defaults.withCredentials = true
Vue.axios.defaults.crossDomain = true


Vue.axios.interceptors.response.use((response) => {
  return response;
}, async function (error) {

  //  HANDLE REAUTHORIZATION
  if (error.response.status === 401) {
    const refreshTokenUrl = '/api/easyauth/v2/token'
    const origConfig = error.response.config
    
    if (origConfig.url !== refreshTokenUrl && !origConfig._retry) {
      origConfig._retry = true
      await store.dispatch('auth/refreshAccessToken')

      // Replace new Token in Header
      origConfig.headers = await store.dispatch('auth/getHeaders', null, { root: true })
      return axios.request(origConfig)
    } else {
      // await store.dispatch('auth/makeLogout')
      router.push({ name: 'Login' })
    } 
  }
  
  return Promise.reject(error)
})










// Vue Moment
Vue.use(require('vue-moment'));


// DEBUG MODE
Vue.mixin({
  data: function () {
    return {
      get isDebugMode() {
        return false;
      }
    }
  }
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
