import Vue from 'vue'
import VueRouter from 'vue-router'

import Dashboard from '../layouts/Dashboard.vue'
import Login from '../views/pages/Login.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'login' }
  },
  {
    path: '',
    redirect: { name: 'login' }
  },
  {
    path: '/login',
    name: 'login',
    // component: () => import('../views/pages/Login.vue'),
    component: Login,
  },

  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/pages/Signup.vue'),
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/dashboard/calender',
        name: 'dashboard.calender',
        component: () => import('../views/dashboard/Calender.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/dashboard/bookings',
        name: 'dashboard.bookings',
        component: () => import('../views/dashboard/Bookings.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/dashboard/eventtypes',
        name: 'dashboard.eventtypes',
        component: () => import('../views/dashboard/EventTypes.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/dashboard/timeslots',
        name: 'dashboard.timeslots',
        component: () => import('../views/dashboard/TimeSlots.vue'),
        meta: { requiresAuth: true },
      },

      // USER-SETTINGS
      // {
      //   path: '/dashboard/user/profile',
      //   name: 'dashboard.profile',
      //   component: () => import('../views/user/Profile.vue'),
      //   meta: { requiresAuth: true },
      // },
      {
        path: '/dashboard/user/settings',
        name: 'dashboard.settings',
        component: () => import('../views/user/Settings.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/dashboard/user/administration',
        name: 'dashboard.administration',
        component: () => import('../views/user/Administration.vue'),
        meta: { requiresAuth: true },
      },
    ]
  },
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})




import store from '@/store/index.js'

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (await store.dispatch('auth/isAuthenticated')) {
      next()
      return
    } else {
      // Try Refreshtoken
      await store.dispatch("auth/refreshAccessToken")
      if (await store.dispatch('auth/isAuthenticated')) {
        await store.dispatch('calender/loadDefaultCalender')
        next()
        return
      }
    }


    if (from.name === "login") {
      return
    } else {
      next({ name: "login" })
    }

  } else {

    // Clear state on logout
    if ((from.name !== null) && (to.name === "login")) {
      window.location.reload()
    }

    next()
  }
})


export default router
