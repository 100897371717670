<template>

    <vs-navbar class="navbar" v-model="activeItem" text-color="rgb(61, 59, 59)" active-text-color="rgb(11, 22, 104)">
        <!-- active-text-color="rgb(246, 171, 54)">rgb(11, 22, 104) -->


        <div slot="title">
            <vs-navbar-title>
                <router-link :to="{ name: 'dashboard.calender'}">
                    <img class="brandImage" @click="activeItem=0"
                        src="../../assets/images/bookmee_de_logo_noslogan_thin.png" />
                </router-link>
            </vs-navbar-title>
        </div>

        <!-- <vs-navbar-item>
                <vs-select class="w-100" v-model="select2">
                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item, index in options" />
                </vs-select>
            </vs-navbar-item> -->

        <!-- 
        <vs-navbar-item index="0">
            <router-link to="/home">
                <vs-row>
                    <span class="mt-1 me-2">Übersicht</span>
                    <vs-icon icon="mood"></vs-icon>
                </vs-row>
            </router-link>
        </vs-navbar-item>

        <vs-navbar-item index="1">
            <router-link to="/home">Übersicht</router-link>
        </vs-navbar-item> -->


        <!-- <vs-navbar-item index="4">
        <vs-dropdown>
            <a class="text-white" href="#">
              Administration
              <vs-icon class="" icon="expand_more"></vs-icon>
            </a>

          <vs-dropdown-menu style="width:200px">
            <vs-dropdown-item :to="{ name: 'app.users'}">
              Benutzer Hinzufügen
            </vs-dropdown-item>
            <vs-dropdown-item :to="{ name: 'app.supplier'}">
              Lieferant Hinzufügen
            </vs-dropdown-item>
            
          </vs-dropdown-menu>
        </vs-dropdown>
      </vs-navbar-item> -->



        <vs-navbar-item index="3">
            <vs-dropdown>
                <vs-avatar :text="user.fname + ' ' + user.lname" />

                <vs-dropdown-menu style="width:200px">
                    <vs-dropdown-item :to="{ name: 'dashboard.settings'}">
                        Einstellungen
                    </vs-dropdown-item>

                    <!-- <vs-dropdown-item :to="{ name: 'dashboard.administration'}">
                        Verwaltung
                    </vs-dropdown-item> -->

                    <!-- <vs-dropdown-item :to="{ name: 'dashboard.settings'}">
                        Passwort Ändern
                    </vs-dropdown-item> -->

                    <vs-dropdown-item divider @click="logout()">
                        Abmelden
                    </vs-dropdown-item>

                    <!-- 
                    <vs-dropdown-item divider>
                        <a class="text-black" href="https://bookmee.de/terms-of-business/" target="_blank">AGB</a>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                        <a class="text-black" href="https://bookmee.de/data-protection/" target="_blank">Datenschutz</a>
                    </vs-dropdown-item> -->

                </vs-dropdown-menu>
            </vs-dropdown>
        </vs-navbar-item>

    </vs-navbar>


</template>
<script>
// import PasswordChangePopup from '@/components/PasswordChangePopup.vue'


export default {
    name: "Navbar",

    components: {

    },

    data() {
        return {
            activeItem: 0,
            options: [
                { text: 'Kalender 1', value: 'Kalender 1' },
                { text: 'Kalender 2', value: 'Kalender 2' },
                { text: 'Kalender 3', value: 'Kalender 3' },
            ],
        }
    },

    computed: {
        user() {
            return this.$store.getters['auth/getUser']
        }
    },

    methods: {
        async logout() {
            await this.$store.dispatch('auth/logout')
            this.$vs.notify({
                time: 5000,
                title: 'Sie wurden abgemeldet.',
                text: '',
                color: 'dark'
            })
            this.$router.push({ name: "login"})
        }        
    },


}
</script>


<style scoped>
/* .btn-responsive-line {
    background-color: #fff !important;
} */
.navbar {
    background-color: rgb(255, 255, 255) !important;
    border-radius: 0 0 2rem 2rem !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px !important;
}

.vs-navbar--item {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    max-width: 90% !important;
}

.brandImage {
    margin-top: 0.5rem;
    height: 2rem !important;
}

</style>