import Vue from 'vue'



export default {
    namespaced: true,
    state: {
        selectedCalender: null
    },


    getters: {
        getSelectedCalender(state) {
            return state.selectedCalender
        }
    },


    mutations: {
        setSelectedCalender(state, calender) {
            state.selectedCalender = calender
        },
    },


    actions: {
        async loadDefaultCalender(ctx) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })

            return Vue.prototype.axios({
                method: "get",
                headers: headers,
                url: '/api/bookmee/calender',
            }).then( resp => {
                const calenders = resp.data.values.calenders

                // Chck if calender exists
                if (calenders.length == 0) {
                    console.log("No Calender available")
                } else {
                    //  By default selct 1st calender
                    // console.log(calenders[0])
                    ctx.commit('setSelectedCalender', calenders[0])
                }
            }).catch(async error => {
                if (error.response.status == 404) {
                    // Create Calender
                    await ctx.dispatch('createCalender', {
                        name: "default",
                        description: "Created by FE"
                    })
                    await ctx.dispatch('loadDefaultCalender')
                }
                console.log(error)
            })
        },   


        async createCalender(ctx, data) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })

            return Vue.prototype.axios({
                method: "post",
                headers: headers,
                url: '/api/bookmee/calender',
                data: data,
            }).then(async resp => {
                // const calenders = resp.data.values.calenders
                console.log(resp.status)
                await ctx.dispatch('loadDefaultCalender')

            }).catch(error => {
                console.log(error)
            })
        },   

    },
}

