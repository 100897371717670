import Vue from 'vue'



export default {
    namespaced: true,
    
    
    state: {
        booking: {},
        bookings: []
    },
    
    
    getters: {
        getBookings(state) {
            return state.bookings 
        },
        getBooking(state) {
            return state.booking
        }
    },
    
    
    mutations: {
        setBookings(state, bookings) {
            if (bookings === null) {
                state.bookings = []
            } else {
                bookings.sort((a, b) => a.start < b.start ? -1 : 1)
                state.bookings = bookings
            }
        },
        setBooking(state, booking) {
            if (booking === null) {
                state.booking = {}
            } else {
                state.booking = booking
            }
        }
    },
    
    
    actions: {
        async loadBookings(ctx) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })
            const selectedCalender = ctx.rootGetters['calender/getSelectedCalender']

            const url = '/api/bookmee/calender/' + selectedCalender.id + '/booking'
            return await Vue.prototype.axios({
                method: "get",
                url: url,
                headers: headers
            }).then(resp => {
                const bookings = resp.data.values.bookings
                ctx.commit('setBookings', bookings)
            }).catch(error => {
                console.log(error)
                ctx.commit('setBookings', null)
            })
        },

        async loadBooking(ctx, bookingId) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })
            const selectedCalender = ctx.rootGetters['calender/getSelectedCalender']

            const url = '/api/bookmee/calender/' + selectedCalender.id + '/booking/' + bookingId
            return await Vue.prototype.axios({
                method: "get",
                url: url,
                headers: headers
            }).then(resp => {
                const booking = resp.data.values.booking
                ctx.commit('setBooking', booking)
            }).catch(error => {
                console.log(error)
                ctx.commit('setBooking', null)
            })
        },


        async createBooking(ctx, {booking, eventTypeId}) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })
            const selectedCalender = ctx.rootGetters['calender/getSelectedCalender']

            const url = '/api/bookmee/calender/' + selectedCalender.id + '/eventtype/' + eventTypeId + '/booking'
            return await Vue.prototype.axios({
                method: "post",
                url: url,
                headers: headers,
                data: booking
            }).then(resp => {
                console.log(resp.status)
                ctx.dispatch("loadBookings")

                ctx.dispatch('calender/loadDefaultCalender', null, { root: true })
                // const bookings = resp.data.values.bookings
                // ctx.commit('setBookings', bookings)
            }).catch(error => {
                console.log(error)
                // ctx.commit('setBookings', null)
            })
        },



        async deleteBooking(ctx, {bookingId, params={}}) {
            const selectedCalender = ctx.rootGetters['calender/getSelectedCalender']
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })

            const url = '/api/bookmee/calender/' + selectedCalender.id + '/booking/' + bookingId
            return await Vue.prototype.axios({
                method: "delete",
                url: url,
                headers: headers,
                params: params
            }).then(resp => {
                console.log(resp.status)
                ctx.dispatch('loadBookings')
                ctx.dispatch('calender/loadDefaultCalender', null, { root: true })
            }).catch(error => {
                console.log(error)
                // Vue.prototype.$toast.error("Buchung konnte nicht abgesagt werden")
            })
        }



    },
}

