import Vue from 'vue'



export default {
    namespaced: true,
    state: {
        eventType: {},
        eventTypes: []
    },
    getters: {
        getEventTypes(state) {
            return state.eventTypes 
        },
        getEventType(state) {
            return state.eventType
        }

    },
    mutations: {
        addEventType(state, item) {
            state.eventTypes.push(item)
        },
        setEventTypes(state, eventTypes) {
            state.eventTypes = eventTypes
        },
        setEventType(state, eventType) {
            state.eventType = eventType
        },
    },
    actions: {
        async loadEventTypes(ctx) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })
            const selectedCalender = ctx.rootGetters['calender/getSelectedCalender']

            const url = '/api/bookmee/calender/' + selectedCalender.id + '/eventtype'
            return await Vue.prototype.axios({
                method: "get",
                url: url,
                headers: headers 
            }).then(resp => {
                const eventTypes = resp.data.values.eventtypes
                ctx.commit('setEventTypes', eventTypes)
            }).catch(error => {
                console.log(error)
            })
        },

        async loadEventType(ctx, id) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })
            const selectedCalender = ctx.rootGetters['calender/getSelectedCalender']

            const url = '/api/bookmee/calender/' + selectedCalender.id + '/eventtype/' + id
            return await Vue.prototype.axios({
                method: "get",
                url: url,
                headers: headers
            }).then(resp => {
                const eventType = resp.data.values.eventtype
                ctx.commit('setEventType', eventType)
            }).catch(error => {
                console.log(error)
            })
        },


        async createEventType(ctx, eventType) {
            const selectedCalender = ctx.rootGetters['calender/getSelectedCalender']           
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })

            const url = '/api/bookmee/calender/' + selectedCalender.id + '/eventtype'
            return await Vue.prototype.axios({
                method: "post",
                url: url,
                headers: headers,
                data: eventType
            }).then(resp => {
                const eventTypes = resp.data.values.eventtypes
                ctx.commit('setEventTypes', eventTypes)
                ctx.dispatch('loadEventTypes')
            }).catch(error => {
                console.log(error)
            })
        },

        async deleteEventType(ctx, id) {
            const selectedCalender = ctx.rootGetters['calender/getSelectedCalender']
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })

            const url = '/api/bookmee/calender/' + selectedCalender.id + '/eventtype/' + id
            return await Vue.prototype.axios({
                method: "delete",
                url: url,
                headers: headers,
            }).then( resp => {
                console.log(resp.status)
                ctx.dispatch('loadEventTypes')
            }).catch(error => {
                console.log(error)
                ctx.dispatch('loadEventTypes')
            })
        },



        // updateEventType
        async updateEventType(ctx, eventType) {
            const selectedCalender = ctx.rootGetters['calender/getSelectedCalender']
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })

            const url = '/api/bookmee/calender/' + selectedCalender.id + '/eventtype/' + eventType.id
            return await Vue.prototype.axios({
                method: "put",
                url: url,
                headers: headers,
                data: eventType
            }).then(resp => {
                const eventType = resp.data.values.eventtype
                ctx.commit('setEventType', eventType)
                ctx.dispatch('loadEventTypes')
            }).catch(error => {
                console.log(error)
            })
        },


    },
}

