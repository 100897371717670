import Vue from 'vue'
// import router from '@/router/index.js'

export default {
    namespaced: true,
    state: {
        isAuthenticated: false,
        user: null,
        headers: {},
        tokens: {
            access: {
                token: null,
                exp: null
            },
            refresh: {
                token: null
            }
        }
    },
    getters: {
        getAccessToken(state) {
            return state.tokens.access.token
        },
        getHeaders(state) {
            return state.headers
        },
        getUser(state) {
            return state.user
        }
    },
    mutations: {
        setIsAuthenticated(state, status) {
            if (status) {
                // Set Expire Date
                state.tokens.access.exp = Math.floor(Date.now() / 1000) + (60 * 30)
            } else {
                state.tokens.access.exp = null
            }
            state.isAuthenticated = status
        },
        setAccessToken(state, accessToken) {
            // state.tokens.access.setAt = Math.floor(Date.now() / 1000)
            state.tokens.access.token = accessToken
        },
        deleteAccessToken(state) {
            state.tokens.access.token = null
        },
        setUser(state, user) {
            state.user = user
        },
        deleteUser(state) {
            state.user = null
        },
        setHeaders(state, headers) {
            state.headers = headers
        }
    }, 
    actions: {
        async logout(ctx) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })

            return Vue.prototype.axios({
                method: "get",
                headers: headers,
                url: '/api/easyauth/v2/logout',
            }).then(async resp => {
                console.log(resp.status)
                // reset Token
                ctx.commit('setAccessToken', null)
                // ctx.commit('setIsAuthenticated', false)
                // router.push({ name: "Login" })
            }).catch(error => {
                console.log(error)
            })
        },


        async createUser(ctx, user) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })

            return Vue.prototype.axios({
                method: "post",
                headers: headers,
                url: '/api/easyauth/v2/user',
                data: user
            }).then(async resp => {
                console.log(resp.data)

                // router.push({ name: "Login" })
            }).catch(error => {
                console.log(error)
            })
        },
   

        async updateUser(ctx, user) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })

            return Vue.prototype.axios({
                method: "put",
                headers: headers,
                url: `/api/easyauth/v2/user/${user.id}`,
                data: user
            }).then(async resp => {
                console.log(resp.data)

                // router.push({ name: "Login" })
            }).catch(error => {
                console.log(error)
            })
        },



        async createLogin(ctx, user) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })

            return Vue.prototype.axios({
                method: "post",
                headers: headers,
                url: '/api/easyauth/v2/login',
                data: user
            }).then(async resp => {
                // Set Token
                let accessToken = resp.data.values.access
                console.log(accessToken)
                ctx.commit('setAccessToken', accessToken)
                
                await ctx.dispatch("loadUser")
            }).catch(error => {
                console.log(error)
            })
        },



        async refreshAccessToken(ctx) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })

            return Vue.prototype.axios({
                method: "get",
                headers: headers,
                url: '/api/easyauth/v2/token'
            }).then(async resp => {
                // Set Token
                let accessToken = resp.data.values.access
                console.log(accessToken)
                ctx.commit('setAccessToken', accessToken)

                await ctx.dispatch("loadUser")
            }).catch(error => {
                console.log(error)
            })
        },


        async loadUser(ctx) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })

            return Vue.prototype.axios({
                method: "get",
                headers: headers,
                url: '/api/easyauth/v2/user',
                params: {
                    self: "True"
                }
            }).then((resp) => {
                ctx.commit("setUser", resp.data.values.user)
            }).catch(error => {
                console.log(error)
            })
        },

        async isAuthenticated(ctx) {
            // Check Token exists
            if (ctx.getters['getAccessToken']) {
                return true
                // console.log("HEHHEHE")
                // //  Check Expire time
                // let now = Math.floor(Date.now() / 1000)
                // if (now < ctx.state.tokens.access.exp) {
                //     return true
                // } else {
                //     //  Remove tokens
                //     ctx.dispatch('logout')
                // }
            } else {
                // Try RefreshToken
                // console.log("Try reauthentication with refreh token")
                // await ctx.dispatch('refreshAccessToken')
                if (ctx.getters.getAccessToken) {
                    return true
                }
            }

            return false
        },

        async getHeaders(ctx) {
            if (await ctx.dispatch('isAuthenticated')) {
                const headers = {
                    // "Access-Control-Allow-Credentials": "true",
                    // 'Access-Control-Allow-Origin': 'orgin',
                    // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    'Authorization': `Bearer ${ctx.getters['getAccessToken']}`,
                }
                ctx.commit("setHeaders", headers)
                return headers
            } else {
                return {
                    // "Access-Control-Allow-Credentials": "true",
                    // 'Access-Control-Allow-Origin': 'orgin',
                    // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                }
            }
        },



    }
}
