<template>
    <div class="container">
        <div class="appNavbar">

            <!-- <vs-navbar class="navbar border" v-model="activeItem" text-color="rgb(61, 59, 59)"
                active-text-color="rgb(11, 22, 104)"> -->


            <div class="nav-item">
                <router-link 
                    class="nav-link pt-1 pb-1 ps-3 pe-3"
                    :to="{ name: 'dashboard.calender' }">
                    Übersicht
                </router-link>
            </div>
            <div class="nav-item">
                <router-link 
                    class="nav-link pt-1 pb-1 ps-3 pe-3"
                    :to="{ name: 'dashboard.bookings' }">
                    Buchungen
                </router-link>
            </div>
            <div class="nav-item">
                <router-link 
                    class="nav-link pt-1 pb-1 ps-3 pe-3"
                    :to="{ name: 'dashboard.timeslots' }">
                    Verfügbarkeiten
                </router-link>
            </div>
            <div class="nav-item">
                <router-link class="nav-link pt-1 pb-1 ps-3 pe-3" :to="{ name: 'dashboard.eventtypes' }">
                    Terminarten
                </router-link>
            </div>


        </div>
    </div>
</template>
<script>
// import PasswordChangePopup from '@/components/PasswordChangePopup.vue'


export default {
    name: "AppNavbar",

    components: {

    },

    data() {
        return {
            activeItem: 0
        }
    },

    computed: {

    },

    methods: {
    },


}
</script>


<style scoped>
/* .btn-responsive-line {
    background-color: #fff !important;
} */
.appNavbar {
    display: flex;
    /* flex-direction: row; */
    flex-flow: wrap;
    justify-content: center !important;
    background-color: rgb(248, 245, 245) !important;
    border: 1px solid rgb(224, 224, 224);
    /* border-radius: 0 0 1rem 1rem !important; */
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px !important; */
}

.vs-navbar--item {
    /* margin-left: 1rem !important;
    margin-right: 1rem !important; */
}

.nav-item {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
    text-align: center;
}

.nav-link  {
    width: 8rem;
    margin-top: 0.15rem;
    margin-bottom: 0.15rem;
    font-size: 0.8rem;
    color: black !important;
    border: 2px solid transparent !important;
    border-radius: 0.2rem !important;
}

.nav-link:hover {
    color: rgb(11, 22, 104) !important;
    background-color: rgb(243, 243, 243) !important;
    border: 2px solid !important;
    transition-duration: 750ms;
}

</style>