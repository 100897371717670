import Vue from 'vue'



export default {
    namespaced: true,
    
    
    state: {
        timeSchedule: {},
        timeSchedules: []
    },
    
    
    getters: {
        getTimeSchedule(state) {
            return state.timeSchedule 
        },
        getTimeSchedules(state) {
            return state.timeSchedules
        }
    },
    
    
    mutations: {
        setTimeSchedule(state, timeSchedule) {
            state.timeSchedule = timeSchedule
        },
        setTimeSchedules(state, timeSchedules) {
            state.timeSchedules = timeSchedules
        },
    },
    
    
    actions: {
        async loadTimeSchedules(ctx) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })
            const selectedCalender = ctx.rootGetters['calender/getSelectedCalender']

            const url = '/api/bookmee/calender/' + selectedCalender.id + '/timeschedule'
            return await Vue.prototype.axios({
                method: "get",
                url: url,
                headers: headers
            }).then(resp => {
                console.log(resp.status)
                const timeSchedules = resp.data.values.timeschedules
                // Set first one as default: Only one timeschedule per calender
                ctx.commit('setTimeSchedule', timeSchedules[0])
                ctx.commit('setTimeSchedules', timeSchedules)
            }).catch(error => {
                console.log(error)
                ctx.commit('setTimeSchedules', [])
            })
        },

        async loadTimeSchedule(ctx, timeScheduleId) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })
            const selectedCalender = ctx.rootGetters['calender/getSelectedCalender']

            const url = '/api/bookmee/calender/' + selectedCalender.id + '/timeschedule/' + timeScheduleId
            return await Vue.prototype.axios({
                method: "get",
                url: url,
                headers: headers
            }).then(resp => {
                const timeSchedule = resp.data.values.timeschedule
                ctx.commit('setTimeSchedule', timeSchedule)
            }).catch(error => {
                console.log(error)
                ctx.commit('setTimeSchedule', {})
            })
        },

        async createTimeSchedule(ctx, timeSchedule) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })
            const selectedCalender = ctx.rootGetters['calender/getSelectedCalender']

            const url = '/api/bookmee/calender/' + selectedCalender.id + '/timeschedule'
            return await Vue.prototype.axios({
                method: "post",
                url: url,
                data: timeSchedule,
                headers: headers
            }).then(resp => {
                console.log(resp.status)
                ctx.dispatch('loadTimeSchedules')
                ctx.dispatch('timeSlots/loadTimeSlots', null, { root: true })
            }).catch(error => {
                console.log(error)
                // Vue.prototype.$toast.error("TimeSlot konnte nicht hinzugefügt werden")
            })
        },

        async updateTimeSchedule(ctx, timeSchedule) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })
            const selectedCalender = ctx.rootGetters['calender/getSelectedCalender']

            const url = '/api/bookmee/calender/' + selectedCalender.id + '/timeschedule/' + timeSchedule.id
            return await Vue.prototype.axios({
                method: "put",
                url: url,
                data: timeSchedule,
                headers: headers
            }).then(resp => {
                console.log(resp.status)
                ctx.dispatch('loadTimeSchedules')
                ctx.dispatch('timeSlots/loadTimeSlots', null, { root: true })
            }).catch(error => {
                console.log(error)
                // Vue.prototype.$toast.error("TimeSlot konnte nicht hinzugefügt werden")
            })
        },
        // async deleteTimeSlot(ctx, id) {
        //     const selectedCalender = ctx.rootGetters['calender/getSelectedCalender']
        //     const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })

        //     const url = '/api/bookmee/calender/' + selectedCalender.id + '/timeslot/' + id
        //     return await Vue.prototype.axios({
        //         method: "delete",
        //         url: url,
        //         headers: headers
        //     }).then(resp => {
        //         console.log(resp.status)
        //         ctx.dispatch('loadTimeSlots')
        //     }).catch(error => {
        //         console.log("error")
        //         console.log(error)
        //     })
        // }

        


    },
}

